export const INIT_CUSTOMER_DETAILS = 'INIT_CUSTOMER_DETAILS';
export const INIT_TEMPLATE_DETAILS = 'INIT_TEMPLATE_DETAILS';
export const ADD_NEW_TEMPLATE = 'ADD_NEW_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const INIT_CUSTOMER_QR_VOUCHER = 'INIT_CUSTOMER_QR_VOUCHER';
export const INIT_LOYALTY = 'INIT_LOYALTY';
export const ADD_LOYALTY = 'ADD_LOYALTY';
export const DELETE_LOYALTY = 'DELETE_LOYALTY';
export const INIT_QR_VOUCHER_APPROVAL = 'INIT_QR_VOUCHER_APPROVAL';
export const INIT_QR_VOUCHER_APPROVAL_REPORT =
  'INIT_QR_VOUCHER_APPROVAL_REPORT';
export const INIT_USER_LOCATION = 'INIT_USER_LOCATION';
export const INIT_QR_VOUCHER_BANNER = 'INIT_QR_VOUCHER_BANNER';
export const INIT_GLIDE = 'INIT_GLIDE';
