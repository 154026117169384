import * as actionTypes from '../../actionTypes';

const initialState = {
  fbConfig: {},
  fbCampaign: [],
  selectedFBCampaign: {},
  fbAudience: [],
  selectedFBAudience: {},
  fbAdset: [],
  selectedFBAdset: {},
  fbAdCreation: [],
  selectedFBAdCreation: {},
  fbAd: [],
};

export default function retentionReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_FB_CONFIG:
      return {
        ...state,
        fbConfig: action.payload,
      };
    case actionTypes.INIT_FB_CAMPAIGN:
      return {
        ...state,
        fbCampaign: action.payload,
        selectedFBCampaign: {},
        fbAudience: [],
        selectedFBAudience: {},
        fbAdset: [],
        selectedFBAdset: {},
        fbAdCreation: [],
        selectedFBAdCreation: {},
        fbAd: [],
      };
    case actionTypes.SELECTED_FB_CAMPAIGN:
      return {
        ...state,
        selectedFBAudience: {},
        selectedFBAdset: {},
        selectedFBAdCreation: {},
        selectedFBCampaign: action.payload,
      };
    case actionTypes.INIT_FB_AUDIENCE:
      return {
        ...state,
        fbAudience: action.payload,
      };
    case actionTypes.SELECTED_FB_AUDIENCE:
      return {
        ...state,
        selectedFBAdset: {},
        selectedFBAdCreation: {},
        selectedFBAudience: action.payload,
      };
    case actionTypes.INIT_FB_ADSET:
      return {
        ...state,
        fbAdset: action.payload,
      };
    case actionTypes.SELECTED_FB_ADSET:
      return {
        ...state,
        selectedFBAdCreation: {},
        selectedFBAdset: action.payload,
      };
    case actionTypes.INIT_FB_ADCREATION:
      return {
        ...state,
        fbAdCreation: action.payload,
      };
    case actionTypes.SELECTED_FB_ADCREATION:
      return {
        ...state,
        selectedFBAdCreation: action.payload,
      };
    case actionTypes.INIT_FB_AD:
      return {
        ...state,
        fbAd: action.payload,
      };
    case 'LOGOUT':
      return {
        fbConfig: {},
        fbCampaign: [],
        selectedFBCampaign: {},
        fbAudience: [],
        selectedFBAudience: {},
        fbAdset: [],
        selectedFBAdset: {},
        fbAdCreation: [],
        selectedFBAdCreation: {},
        fbAd: [],
      };
    default:
      return state;
  }
}
