export const INIT_DASHBOARD_BUSINESS = 'INIT_DASHBOARD_BUSINESS';
export const INIT_DASHBOARD_BUSINESS_COMPARE =
  'INIT_DASHBOARD_BUSINESS_COMPARE';
export const INIT_DASHBOARD_MENU = 'INIT_DASHBOARD_MENU';
export const INIT_DASHBOARD_MENU_COMBOS = 'INIT_DASHBOARD_MENU_COMBOS';
export const INIT_DASHBOARD_ORDER = 'INIT_DASHBOARD_ORDER';
export const INIT_DASHBOARD_ORDER_REVENUE_LEAKAGE =
  'INIT_DASHBOARD_ORDER_REVENUE_LEAKAGE';
export const INIT_DASHBOARD_CUSTOMER = 'INIT_DASHBOARD_CUSTOMER';
export const INIT_DASHBOARD_INVENTORY = 'INIT_DASHBOARD_INVENTORY';
export const CLEAR_DASHBOARD_COMPARE = 'CLEAR_DASHBOARD_COMPARE';
