import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';
import {toast} from 'react-toastify';

export const getStoreSubscriptionList = () => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restaurant._id;
    http
      .get(`${API_URL}/subscription/store/${storeID}`)
      .then((res) => {
        dispatch({type: 'INIT_STORE_SUBSCRIPTIONS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get subscription details');
        dispatch(setLoading(false));
      });
  };
};

export const getSubscribersList = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/preorder`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_SUBSCRIBERS, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get customer details');
        dispatch(setLoading(false));
      });
  };
};

export const addNewSubscriber = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/preorder`, data)
      .then((res) => {
        dispatch({type: actionTypes.ADD_NEW_SUBSCRIBERS, payload: res.data});
        successCB();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to add new customer details');
        dispatch(setLoading(false));
      });
  };
};

export const editSubscriber = (id, data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/preorder/${id}`, data)
      .then((res) => {
        dispatch({type: actionTypes.EDIT_SUBSCRIBERS, payload: res.data});
        successCB();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to update customer details');
        dispatch(setLoading(false));
      });
  };
};

export const deleteSubscriber = (id, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/preorder/${id}`)
      .then(() => {
        dispatch({type: actionTypes.DELETE_SUBSCRIBERS, payload: id});
        dispatch(setLoading(false));
        successCB();
      })
      .catch(() => {
        toast.error('Unable to delete customer details');
        dispatch(setLoading(false));
      });
  };
};

export const generateSubscriberPaymentLink = (data, successCB) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restaurant._id;
    http
      .post(`${API_URL}/subscription/${storeID}/generate/link`, data)
      .then((res) => {
        successCB();
        dispatch({type: actionTypes.ADD_TO_SUBSCRIPTION, payload: res.data});
        toast.success('Payment link was generated and sent to user');
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Unable to generate payment link');
        dispatch(setLoading(false));
      });
  };
};

export const resendPaymentlink = (ID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/payment_link/${ID}/resend`)
      .then(() => {
        toast.success('Payment link was resent to user');
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Unable to resend payment link');
        dispatch(setLoading(false));
      });
  };
};
