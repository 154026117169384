import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {setLoading} from '../miscActions';
import {http} from '../../http';
// import {toast} from 'react-toastify';

export const getRetentionConfig = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/fb/info/store`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_FB_CONFIG,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createNewRetentionConfig = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/fb/store/details`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_FB_CONFIG,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateToken = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/fb/token`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_FB_CONFIG,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getCampaignList = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/fb/store/campaign`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_FB_CAMPAIGN,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createNewFBCampaign = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/fb/store/campaign`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch(getCampaignList());
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteFBCampaign = (id, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/fb/store/campaign/${id}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch(getCampaignList());
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getAudienceList = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/fb/ad/details?id=${id}&type=campaign_id`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_FB_AUDIENCE,
          payload: res.data,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setSelectedFBCampaign = (data) => {
  return (dispatch) => {
    if (!data._id) {
      dispatch({
        type: actionTypes.SELECTED_FB_CAMPAIGN,
        payload: {},
      });
    } else {
      dispatch(getAudienceList(data._id));
      dispatch({
        type: actionTypes.SELECTED_FB_CAMPAIGN,
        payload: data,
      });
    }
  };
};

export const createNewFBAudience = (campaignID, data, successCB) => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/fb/store/audience/${storeID}/${campaignID}`, data)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getAudienceList(campaignID));
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getAdsetList = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/fb/ad/details?id=${id}&type=audience_id`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_FB_ADSET,
          payload: res.data,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setSelectedFBAudience = (data) => {
  return (dispatch) => {
    if (!data._id) {
      dispatch({
        type: actionTypes.SELECTED_FB_AUDIENCE,
        payload: {},
      });
    } else {
      dispatch(getAdsetList(data.audience_id));
      dispatch({
        type: actionTypes.SELECTED_FB_AUDIENCE,
        payload: data,
      });
    }
  };
};

export const getAdCreationList = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/fb/ad/details?id=${id}&type=adset_id`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_FB_ADCREATION,
          payload: res.data,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setSelectedFBAdset = (data) => {
  return (dispatch) => {
    if (!data._id) {
      dispatch({
        type: actionTypes.SELECTED_FB_ADSET,
        payload: {},
      });
    } else {
      dispatch(getAdCreationList(data.adset_id));
      dispatch({
        type: actionTypes.SELECTED_FB_ADSET,
        payload: data,
      });
    }
  };
};

export const createNewFBAdset = (ID, audienceID, data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/fb/store/adset/${ID}`, data)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getAdsetList(audienceID));
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateFBAdset = (ID, audienceID, data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/fb/store/adset/${ID}`, data)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getAdsetList(audienceID));
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getAdList = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/fb/ad/details?id=${id}&type=adcreative_id`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_FB_AD,
          payload: res.data,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setSelectedFBAdCreation = (data) => {
  return (dispatch) => {
    if (!data._id) {
      dispatch({
        type: actionTypes.SELECTED_FB_ADCREATION,
        payload: {},
      });
    } else {
      dispatch(getAdList(data.adcreative_id));
      dispatch({
        type: actionTypes.SELECTED_FB_ADCREATION,
        payload: data,
      });
    }
  };
};

export const createNewFBAdCreative = (
  id,
  adsetID,
  receivedFile,
  request,
  successCB
) => {
  return (dispatch) => {
    const data = new FormData();
    data.append('files', receivedFile);
    data.append('name', request.name);
    data.append('message', request.message);
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/fb/store/adcreative?id=${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getAdCreationList(adsetID));
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createNewFBAd = (ID, adCreativeID, data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/fb/store/ad/${ID}`, data)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getAdList(adCreativeID));
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};
