import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import {toast} from 'react-toastify';

export const initPayout = (list) => {
  return {
    type: 'INIT_PAYOUT',
    payload: list,
  };
};

export const updateSalesCount = (data) => {
  return {
    type: 'UPDATE_SALES_COUNT',
    payload: data,
  };
};

export const getPayoutInfo = (from, to) => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${restaurantID}/ordersPayment?from=${from}&to=${to}`
      )
      .then((res) => {
        dispatch(initPayout(res.data.result));
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data for given sales date');
        dispatch(setLoading(false));
      });
  };
};

export const getPayoutCountInfo = () => {
  return (dispatch, getState) => {
    const authState = getState();
    const restaurantID = authState.auth.authData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${restaurantID}/orders/details`)
      .then((res) => {
        dispatch(updateSalesCount(res.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data');
        dispatch(setLoading(false));
      });
  };
};

export const getStorePayoutInfo = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/payouts/admin`)
      .then((res) => {
        dispatch({
          type: 'INIT_STORE_PAYOUT',
          payload: res.data,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data');
        dispatch(setLoading(false));
      });
  };
};

export const clearPayoutInfo = () => {
  return (dispatch) => {
    dispatch(initPayout([]));
  };
};
