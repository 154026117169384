import * as actionTypes from '../../actionTypes';

const initialState = {
  customerDetails: [],
};

export default function subscriberReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_SUBSCRIBERS:
      return {
        ...state,
        customerDetails: action.payload,
      };
    case actionTypes.ADD_NEW_SUBSCRIBERS:
      return {
        ...state,
        customerDetails: [...state.customerDetails, action.payload],
      };
    case actionTypes.EDIT_SUBSCRIBERS:
      const updateCust = [...state.customerDetails];
      const updateCustIndex = updateCust.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateCustIndex > -1) {
        updateCust[updateCustIndex] = action.payload;
      }
      return {
        ...state,
        customerDetails: updateCust,
      };
    case actionTypes.DELETE_SUBSCRIBERS:
      const delCust = [...state.customerDetails];
      const delCustIndex = delCust.findIndex((a) => a._id === action.payload);
      if (delCustIndex > -1) {
        delCust.splice(delCustIndex, 1);
      }
      return {
        ...state,
        customerDetails: delCust,
      };
    case actionTypes.ADD_TO_SUBSCRIPTION: {
      const tempSubscriber = [...state.customerDetails];
      const tempSubscriberIndex = tempSubscriber.findIndex(
        (a) => a.phone === action.payload.contact_number
      );
      if (tempSubscriberIndex > -1) {
        tempSubscriber[tempSubscriberIndex]['subscription'].push(
          action.payload
        );
      }
      return {
        ...state,
        customerDetails: tempSubscriber,
      };
    }
    case 'LOGOUT':
      return {
        customerDetails: [],
      };
    default:
      return state;
  }
}
