import * as actionTypes from '../../actionTypes';

const initialState = {
  paytmDetails: [],
  beneficiaryList: [],
  paymentHistory: [],
};

export default function beneficiaryReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_PAYTM_WALLET:
      return {
        ...state,
        paytmDetails: action.payload,
      };
    case actionTypes.INIT_BENEFICIARY:
      return {
        ...state,
        beneficiaryList: action.payload,
      };
    case actionTypes.INIT_PAYTM_HISTORY:
      return {
        ...state,
        paymentHistory: action.payload,
      };
    case actionTypes.UPDATE_PAYTM_HISTORY:
      const tempUpdate = [...state.paymentHistory];
      const tempUpdateIndex = tempUpdate.findIndex(
        (a) => a._id === action.payload._id
      );
      if (tempUpdateIndex > -1) {
        tempUpdate[tempUpdateIndex] = action.payload;
      }
      return {
        ...state,
        paymentHistory: tempUpdate,
      };
    case actionTypes.ADD_BENEFICIARY:
      return {
        ...state,
        beneficiaryList: [...state.beneficiaryList, action.payload],
      };
    case actionTypes.UPDATE_BENEFICIARY:
      const updateBeneficiary = [...state.beneficiaryList];
      const updateIndex = updateBeneficiary.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateIndex > -1) {
        updateBeneficiary[updateIndex] = action.payload;
      }
      return {
        ...state,
        beneficiaryList: updateBeneficiary,
      };
    case actionTypes.DELETE_BENEFICIARY:
      const deleteBeneficiary = [...state.beneficiaryList];
      const deleteIndex = deleteBeneficiary.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteIndex > -1) {
        deleteBeneficiary.splice(deleteIndex, 1);
      }
      return {
        ...state,
        beneficiaryList: deleteBeneficiary,
      };
    case 'LOGOUT':
      return {
        paytmDetails: [],
        beneficiaryList: [],
        paymentHistory: [],
      };
    default:
      return state;
  }
}
