import * as actionTypes from '../../actionTypes';

const initialState = {
  employees: {
    result: [],
    __metadata: {},
  },
  employeeAttendance: [],
  staffAttendance: [],
  salaryDetails: [],
};

export default function employeeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case actionTypes.ADD_NEW_EMPLOYEE:
      const addEmployee = {...state.employees};
      addEmployee.result.push(action.payload);
      return {
        ...state,
        employees: addEmployee,
      };
    case actionTypes.UPDATE_EMPLOYEE:
      const updateEmployee = {...state.employees};
      const updateIndex = updateEmployee.result.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateIndex > -1) {
        updateEmployee.result[updateIndex] = action.payload;
      }
      return {
        ...state,
        employees: updateEmployee,
      };
    case actionTypes.DELETE_EMPLOYEE:
      const deleteEmployee = {...state.employees};
      const deleteIndex = deleteEmployee.result.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteIndex > -1) {
        deleteEmployee.result.splice(deleteIndex, 1);
      }
      return {
        ...state,
        employees: deleteEmployee,
      };
    case actionTypes.INIT_EMPLOYEE_ATTENDANCE:
      return {
        ...state,
        employeeAttendance: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYEE_ATTENDANCE:
      const updateEmployeeAttendance = [...state.employeeAttendance];
      const updateAttendanceIndex = updateEmployeeAttendance.findIndex(
        (a) => a.date === action.payload.date
      );
      if (updateAttendanceIndex > -1) {
        updateEmployeeAttendance[updateAttendanceIndex] = action.payload;
      }
      return {
        ...state,
        employeeAttendance: updateEmployeeAttendance,
      };
    case actionTypes.INIT_ALL_EMPLOYEE_ATTENDANCE:
      return {
        ...state,
        staffAttendance: action.payload,
      };
    case actionTypes.UPDATE_STAFF_ATTENDANCE:
      const updateStaffAttendance = [...state.staffAttendance];
      const updateStaffIndex = updateStaffAttendance.findIndex(
        (a) => a.date === action.payload.date
      );
      if (updateStaffIndex > -1) {
        updateStaffAttendance[updateStaffIndex] = action.payload;
      }
      return {
        ...state,
        staffAttendance: updateStaffAttendance,
      };
    case actionTypes.INIT_EMPLOYEE_SALARY:
      return {
        ...state,
        salaryDetails: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYEE_SALARY:
      const updateSalary = [...state.salaryDetails];
      const updateSalaryIndex = updateSalary.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateSalaryIndex > -1) {
        updateSalary[updateSalaryIndex] = action.payload;
      }
      return {
        ...state,
        salaryDetails: updateSalary,
      };
    case 'LOGOUT':
      return {
        employees: {
          result: [],
          __metadata: {},
        },
        employeeAttendance: [],
        staffAttendance: [],
        salaryDetails: [],
      };
    default:
      return state;
  }
}
