import React, {useEffect, useState, memo} from 'react';
import {useStyles} from './styles';
import MenuIcon from './svgs/MenuIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuDrawer from './components/MenuDrawer';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import HotMenu from './components/HotMenu';
import {useTranslation} from 'react-i18next';
import {getCustomWhatsappStatus} from '../../services/actions';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [, i18n] = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const language = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.opted_languages
      ? state.auth.restaurant.opted_languages
      : []
  );

  const restName = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.business_name
      ? state.auth.restaurant.business_name
      : state.auth.restaurant.name
      ? state.auth.restaurant.name.en
      : ''
  );

  const storeID = useSelector(
    (state) => state.auth.restaurant && state.auth.restaurant._id
  );

  const customWhatsappEnabled = useSelector(
    (state) => state.auth.restaurant && state.auth.restaurant.custom_whatsapp
  );

  useEffect(() => {
    if (storeID && customWhatsappEnabled) {
      dispatch(getCustomWhatsappStatus(storeID, true));
    }
  }, [dispatch, storeID, customWhatsappEnabled]);

  useEffect(() => {
    if (
      window.localStorage.i18nextLng &&
      (language.includes(window.localStorage.i18nextLng.split('-')[0]) ||
        window.localStorage.i18nextLng.split('-')[0] === 'en')
    ) {
      i18n.changeLanguage(window.localStorage.i18nextLng.split('-')[0]);
    } else {
      i18n.changeLanguage('en');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.leftDiv}>
        <div className={classes.flexBox}>
          <IconButton onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
          <div
            className={classes.titleText}
            onClick={() => {
              if (
                window.location.pathname &&
                !window.location.pathname.includes('dashboard')
              ) {
                dispatch(push('/home'));
              }
            }}
          >
            {restName}
          </div>
        </div>
      </div>
      <div className={classes.rightDiv}>
        <HotMenu />
      </div>
      <MenuDrawer open={menuOpen} handleClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default memo(Header);
