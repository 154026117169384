import React, {lazy, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {darkTheme, lightTheme} from './utils/themes/theme';
import './App.scss';
import {Switch, Route} from 'react-router';
import suspenseHoc from './hoc/suspenseHoc';
import PrivateAuthHoc from './hoc/PrivateAuthHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import headerHoc from './hoc/headerHoc';
import LoadingModal from './modules/LoadingModal';
import {toast} from 'react-toastify';
import {useMediaQuery} from '@material-ui/core';

const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const AnalyticsPage = lazy(() => import('./pages/AnalyticsPage'));
const ManageItemsPage = lazy(() => import('./pages/ManageItemsPage'));
const ManageItemsView = lazy(() => import('./views/ManageItemsView'));
const TablePage = lazy(() => import('./pages/TablePage'));
const PreferencesPage = lazy(() => import('./pages/PreferencesPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const ExpensesPage = lazy(() => import('./pages/ExpensesPage'));
// const PayoutPage = lazy(() => import('./pages/PayoutPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const EmployeePage = lazy(() => import('./pages/EmployeePage'));
const PaymentsPage = lazy(() => import('./pages/PaymentsPage'));
const CrmPage = lazy(() => import('./pages/CrmPage'));
const SubscriptionPage = lazy(() => import('./pages/SubscriptionPage'));
const ActiveTablePage = lazy(() => import('./pages/ActiveTablePage'));
const RetentionPage = lazy(() => import('./pages/RetentionPage'));
const VoucherPage = lazy(() => import('./pages/VoucherPage'));
const SocialVouchersPage = lazy(() => import('./pages/SocialVouchersPage'));
const MapMyCustomersPage = lazy(() => import('./pages/MapMyCustomersPage'));
const LoyaltyPage = lazy(() => import('./pages/LoyaltyPage'));
const SMSCampaignPage = lazy(() => import('./pages/SMSCampaignPage'));
const DesignPage = lazy(() => import('./pages/DesignPage'));
const WhatsappPage = lazy(() => import('./pages/WhatsappPage'));
const GlidePage = lazy(() => import('./pages/GlidePage'));
const NotFound = lazy(() => import('./modules/NotFound'));
const CloverIntegrationPage = lazy(() =>
  import('./pages/CloverIntegrationPage')
);

function App() {
  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '22.01.13m');
      } else if (appVersion !== '22.01.13m') {
        toast.info('Please wait, updating new version...');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '22.01.13m');
          window.location.reload();
        }, 3000);
      }
    };
  }, []);
  const matches = useMediaQuery('(min-width:800px)');
  const theme = window.localStorage.theme ? window.localStorage.theme : 'light';
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <LoadingModal />
      <Switch>
        <PrivateAuthHoc
          exact
          path="/home"
          component={suspenseHoc(headerHoc(DashboardPage))}
        />
        <PrivateAuthHoc
          exact
          path="/analytics"
          component={suspenseHoc(headerHoc(AnalyticsPage))}
        />
        <PrivateAuthHoc
          exact
          path="/menu"
          component={
            matches
              ? suspenseHoc(headerHoc(ManageItemsPage))
              : suspenseHoc(headerHoc(ManageItemsView))
          }
        />
        <PrivateAuthHoc
          exact
          path="/tables"
          component={suspenseHoc(headerHoc(TablePage))}
        />
        <PrivateAuthHoc
          exact
          path="/active-tables"
          component={suspenseHoc(headerHoc(ActiveTablePage))}
        />
        <PrivateAuthHoc
          exact
          path="/preferences"
          component={suspenseHoc(headerHoc(PreferencesPage))}
        />
        <PrivateAuthHoc
          path="/reports"
          component={suspenseHoc(headerHoc(ReportsPage))}
        />
        <PrivateAuthHoc
          path="/expenses"
          component={suspenseHoc(headerHoc(ExpensesPage))}
        />
        <PrivateAuthHoc
          exact
          path="/payments"
          component={suspenseHoc(headerHoc(PaymentsPage))}
        />
        {/* <PrivateAuthHoc
          exact
          path="/payout"
          component={suspenseHoc(headerHoc(PayoutPage))}
        /> */}
        <PrivateAuthHoc
          exact
          path="/lead-gen"
          component={suspenseHoc(headerHoc(VoucherPage))}
        />
        <PrivateAuthHoc
          exact
          path="/social-vouchers"
          component={suspenseHoc(headerHoc(SocialVouchersPage))}
        />
        <PrivateAuthHoc
          exact
          path="/crm"
          component={suspenseHoc(headerHoc(CrmPage))}
        />
        <PrivateAuthHoc
          exact
          path="/clover-integration"
          component={suspenseHoc(headerHoc(CloverIntegrationPage))}
        />
        <PrivateAuthHoc
          exact
          path="/map-my-customers"
          component={suspenseHoc(headerHoc(MapMyCustomersPage))}
        />
        <PrivateAuthHoc
          exact
          path="/loyalty"
          component={suspenseHoc(headerHoc(LoyaltyPage))}
        />
        <PrivateAuthHoc
          exact
          path="/sms"
          component={suspenseHoc(headerHoc(SMSCampaignPage))}
        />
        <PrivateAuthHoc
          exact
          path="/whatsapp"
          component={suspenseHoc(headerHoc(WhatsappPage))}
        />
        <PrivateAuthHoc
          exact
          path="/recurring"
          component={suspenseHoc(headerHoc(SubscriptionPage))}
        />
        <PrivateAuthHoc
          exact
          path="/profile"
          component={suspenseHoc(headerHoc(ProfilePage))}
        />
        <PrivateAuthHoc
          exact
          path="/retention"
          component={suspenseHoc(headerHoc(RetentionPage))}
        />
        <PrivateAuthHoc
          exact
          path="/employees"
          component={suspenseHoc(headerHoc(EmployeePage))}
        />
        <PrivateAuthHoc
          exact
          path="/design"
          component={suspenseHoc(headerHoc(DesignPage))}
        />
        <PrivateAuthHoc
          exact
          path="/glide"
          component={suspenseHoc(headerHoc(GlidePage))}
        />
        <LandingAuthHoc exact path="/" component={suspenseHoc(LandingPage)} />
        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
