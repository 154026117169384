import * as actionTypes from '../../actionTypes';

const initialState = {
  storeSongs: [],
  globalSongs: [],
  songQueue: [],
};

export default function jukeboxReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_JUKEBOX_STORE_SONGS:
      return {
        ...state,
        storeSongs: action.payload,
      };
    case actionTypes.INIT_JUKEBOX_GLOBAL_SONGS:
      return {
        ...state,
        globalSongs: action.payload,
      };
    case actionTypes.INIT_JUKEBOX_QUEUE_SONGS:
      return {
        ...state,
        songQueue: action.payload,
      };
    case 'LOGOUT':
      return {
        storeSongs: [],
        globalSongs: [],
        songQueue: [],
      };
    default:
      return state;
  }
}
