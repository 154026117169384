import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {setLoading} from '../miscActions';
import {http} from '../../http';
import {toast} from 'react-toastify';

export const getPaytmDetails = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/paytm`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_PAYTM_WALLET,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get paytm details');
      });
  };
};

export const getPaytmHistory = (from, to) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/paytm/history?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_PAYTM_HISTORY,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get paytm details');
      });
  };
};

export const refreshPaytmHistory = (paymentID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/paytm/${paymentID}/status`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.UPDATE_PAYTM_HISTORY,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get details');
      });
  };
};

export const getBeneficiaryList = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/beneficiary`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_BENEFICIARY,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get beneficiary list');
      });
  };
};

export const addNewBeneficiary = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/beneficiary`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.ADD_BENEFICIARY,
          payload: res.data,
        });
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add beneficiary');
      });
  };
};

export const updateBeneficiary = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/beneficiary/${data._id}`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.UPDATE_BENEFICIARY,
          payload: res.data,
        });
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update beneficiary');
      });
  };
};

export const deleteBeneficiary = (id, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/beneficiary/${id}`)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.DELETE_BENEFICIARY,
          payload: id,
        });
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete beneficiary');
      });
  };
};

export const payForBeneficiary = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/paytm/funds/transfer`, data)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getPaytmDetails());
        if (successCB) {
          successCB();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete beneficiary');
      });
  };
};
