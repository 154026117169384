export const INIT_FB_CONFIG = 'INIT_FB_CONFIG';
export const INIT_FB_CAMPAIGN = 'INIT_FB_CAMPAIGN';
export const SELECTED_FB_CAMPAIGN = 'SELECTED_FB_CAMPAIGN';
export const INIT_FB_AUDIENCE = 'INIT_FB_AUDIENCE';
export const SELECTED_FB_AUDIENCE = 'SELECTED_FB_AUDIENCE';
export const INIT_FB_ADSET = 'INIT_FB_ADSET';
export const SELECTED_FB_ADSET = 'SELECTED_FB_ADSET';
export const INIT_FB_ADCREATION = 'INIT_FB_ADCREATION';
export const SELECTED_FB_ADCREATION = 'SELECTED_FB_ADCREATION';
export const INIT_FB_AD = 'INIT_FB_AD';
