import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import {getCategoryInfo, getGlobalAddonInfo, getItemInfo} from '../../thunks';
import {toast} from 'react-toastify';

export const uploadImages = (receivedFile) => {
  return (dispatch, getState) => {
    const data = new FormData();
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    for (let i = 0; i < receivedFile.length; i++) {
      data.append('files', receivedFile[i]);
    }
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/stores/${restaurantID}/items/images`, data)
      .then(async (res) => {
        if (res.data.added_items > 0) {
          if (res.data.added_items === res.data.total_items) {
            toast.success('successfully updated');
            await dispatch(getCategoryInfo());
            await dispatch(getItemInfo());
            await dispatch(setLoading(false));
          } else {
            toast.warning('partially updated');
            await dispatch(getCategoryInfo());
            await dispatch(getItemInfo());
            await dispatch(setLoading(false));
          }
        } else {
          toast.error('unable to process file');
          await dispatch(setLoading(false));
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to upload file');
      });
  };
};

export const uploadFile = (receivedFile) => {
  return (dispatch, getState) => {
    const data = new FormData();
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    data.append('file', receivedFile);
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/stores/${restaurantID}/items`, data)
      .then(async (res) => {
        if (res.data.error_count === 0) {
          if (res.data.added_count > 0 || res.data.updated_count > 0) {
            toast.success('successfully updated');
            await dispatch(getCategoryInfo());
            await dispatch(getItemInfo());
            await dispatch(setLoading(false));
          } else {
            toast.warning('nothing updated/added');
            await dispatch(setLoading(false));
          }
        } else if (res.data.added_count > 0 || res.data.updated_count > 0) {
          toast.warning('partially updated');
          await dispatch(getCategoryInfo());
          await dispatch(getItemInfo());
          await dispatch(setLoading(false));
        } else {
          toast.error('error found in file');
          await dispatch(setLoading(false));
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to upload file');
      });
  };
};

export const updateItemAvailability = (id, data) => {
  const la = window.localStorage.i18nextLng.split('-')[0]
    ? window.localStorage.i18nextLng.split('-')[0]
    : 'en';
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/items/${id}/toggleAvail?lang=${la}`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_ITEMS', payload: res.data.item});
        toast.success(res.data.message);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to toggle availablility');
      });
  };
};

export const deleteItem = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/items/${id}`)
      .then(() => {
        toast.success('Item deleted successfully');
        dispatch(getItemInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete item');
      });
  };
};

export const addCategory = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/category`, data)
      .then((res) => {
        dispatch({type: 'ADD_CATEGORY', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add category');
      });
  };
};

export const updateCategory = (data, id, lang) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/category/${id}?lang=${lang}`, data)
      .then((res) => {
        dispatch(getCategoryInfo());
        if (data && data.recommendations && data.recommendations.length > 0) {
          dispatch(getItemInfo());
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update category');
      });
  };
};

export const updateCategoryPriority = (id, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/category/${id}/priority`, data)
      .then((res) => {
        dispatch(getCategoryInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update Priority');
      });
  };
};

export const updateItemPriority = (id, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/items/${id}/priority`, data)
      .then(() => {
        dispatch(getItemInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update item Priority');
      });
  };
};

export const deleteCategory = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/category/${id}`)
      .then(() => {
        dispatch(getCategoryInfo());
        dispatch(getItemInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete category');
      });
  };
};

export const addSubCategory = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/sub_category`, data)
      .then(() => {
        dispatch(getCategoryInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add sub category');
      });
  };
};

export const deleteSubCategory = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/sub_category/${id}`)
      .then(() => {
        dispatch(getCategoryInfo());
        dispatch(getItemInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete sub category');
      });
  };
};

export const addItems = (data, lang) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/items?lang=${lang}`, data)
      .then((res) => {
        dispatch({type: 'ADD_ITEMS', payload: res.data.item});
        dispatch(setLoading(false));
        toast.success('Item added successfully');
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('Unable to add Item');
      });
  };
};

export const updateItemImage = (id, receivedFile, lang, cb) => {
  return (dispatch) => {
    const data = new FormData();
    for (let i = 0; i < receivedFile.length; i++) {
      data.append('files', receivedFile[i]);
    }
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/items/${id}/image?lang=${lang}`, data)
      .then((res) => {
        cb();
        toast.success('Item updated successfully');
        dispatch({type: 'UPDATE_ITEMS', payload: res.data.item});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update image');
      });
  };
};

export const updateItemVideo = (id, receivedFile, lang, cb) => {
  return (dispatch) => {
    const data = new FormData();
    data.append('files', receivedFile);
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/items/${id}/video?lang=${lang}`, data)
      .then((res) => {
        cb();
        toast.success('Item updated successfully');
        dispatch({type: 'UPDATE_ITEMS', payload: res.data.item});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update video');
      });
  };
};

export const updateItems = (id, data, lang) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/items/${id}?lang=${lang}`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_ITEMS', payload: res.data.item});
        dispatch(setLoading(false));
        toast.success('Item update successfully');
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update item');
      });
  };
};

export const handleItemUpdate = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/items/${data._id}`, data)
      .then(() => {
        dispatch(getItemInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update item Priority');
      });
  };
};

export const deleteItemImageVideo = (item, type, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/items/${item._id}/media/remove?type=${type}`)
      .then((res) => {
        if (cb) {
          cb();
        }
        dispatch({type: 'UPDATE_ITEMS', payload: res.data.item});
        dispatch(setLoading(false));
        toast.success('Item update successfully');
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update item');
      });
  };
};

export const addAddonToItems = (id, addon) => {
  const la = window.localStorage.i18nextLng.split('-')[0]
    ? window.localStorage.i18nextLng.split('-')[0]
    : 'en';
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/items/${id}/addons?lang=${la}`, addon)
      .then((res) => {
        dispatch({type: 'UPDATE_ITEMS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add addon');
      });
  };
};

export const updateAddonInItems = (id, addon, lang) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/items/${id}/addons/${addon._id}?lang=${lang}`, addon)
      .then((res) => {
        dispatch({type: 'UPDATE_ITEMS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update addon in Item');
      });
  };
};

export const deleteItemAddon = (ITEM_ID, ADDON_ID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/items/${ITEM_ID}/addons/${ADDON_ID}`)
      .then((res) => {
        dispatch({type: 'UPDATE_ITEMS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete addon in item');
      });
  };
};

export const addGlobalAddon = (addon) => {
  const la = window.localStorage.i18nextLng.split('-')[0]
    ? window.localStorage.i18nextLng.split('-')[0]
    : 'en';
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/addons?lang=${la}`, addon)
      .then((res) => {
        dispatch({type: 'ADD_GLOBAL_ADDON', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add global addon');
      });
  };
};

export const updateGlobalAddon = (addon) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/addons/${addon._id}`, addon)
      .then(() => {
        dispatch(getCategoryInfo());
        dispatch(getItemInfo());
        dispatch(getGlobalAddonInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update global addon');
      });
  };
};

export const deleteGlobalAddon = (ADDON_ID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/addons/${ADDON_ID}`)
      .then(() => {
        dispatch(getCategoryInfo());
        dispatch(getItemInfo());
        dispatch(getGlobalAddonInfo());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete global addon');
      });
  };
};

export const addExistingAddon = (item_id, addon_id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/items/${item_id}/addons/${addon_id}`, {})
      .then((res) => {
        dispatch({type: 'UPDATE_ITEMS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add existing addon');
      });
  };
};

export const updateAvailability = (source, data, type, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/availability?type=${type}&id=${source._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        const item = {...source};
        item['availability'] = res.data;
        switch (type) {
          case 'item':
            dispatch({type: 'UPDATE_ITEMS', payload: item});
            break;
          case 'category':
            dispatch(getCategoryInfo());
            break;
          default:
            break;
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update availability');
      });
  };
};
