import axios from 'axios';
import {fetchData, setLoading} from './actions/miscActions';
import {getTaxes, initPreferences} from './actions/preferencesActions';
import {API_URL} from '../utils/constants';
import {toast} from 'react-toastify';
import {http} from './http';

export const getItemInfo = () => {
  return (dispatch, getState) => {
    const id = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${id}/items?limit=1000&page=1&type=all`)
      .then((res) => {
        // dispatch(initItems(res.data));
        dispatch({type: 'INIT_ITEM', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get item info');
      });
  };
};

export const getGlobalAddonInfo = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/addons/store`)
      .then((res) => {
        dispatch({type: 'INIT_GLOBAL_ADDON', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get global addon info');
      });
  };
};

export const getIcons = () => {
  return (dispatch) => {
    axios
      .get(`${API_URL}/icons`)
      .then((res) => dispatch({type: 'SET_ICONS', payload: res.data}))
      .catch(() => {});
  };
};

export const getCategoryInfo = () => {
  return (dispatch, getState) => {
    const id = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${id}/category`)
      .then((res) => {
        dispatch({type: 'INIT_CATEGORY', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(fetchData(false));
        toast.error('Unable to get Category Info');
      });
  };
};

export const getRestVouchers = () => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    http
      .get(`${API_URL}/vouchers/store/${restaurantID}?limit=100&page=1`)
      .then((res) => {
        dispatch({type: 'INIT_VOUCHERS', payload: res.data});
        dispatch(fetchData(false));
      })
      .catch(() => {
        dispatch(fetchData(false));
        toast.error('unable to get Vouchers');
      });
  };
};

export const getPreferences = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/preferences`)
      .then((res) => {
        dispatch(initPreferences(res.data));
        dispatch(fetchData(false));
      })
      .catch(() => {
        dispatch(fetchData(false));
        toast.error('unable to get preference');
      });
  };
};

export const refreshData = () => {
  return async (dispatch) => {
    await dispatch(getItemInfo());
    await dispatch(getGlobalAddonInfo());
    await dispatch(getCategoryInfo());
    await dispatch(getPreferences());
    await dispatch(getRestVouchers());
    await dispatch(getIcons());
    await dispatch(getTaxes());
  };
};
