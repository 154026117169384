const initState = {
  sections: [],
  tables: [],
  activeTable: [],
};

const tablesReducer = (state = initState, action) => {
  switch (action.type) {
    case 'INIT_SECTIONS':
      return {
        ...state,
        sections: action.payload,
      };
    case 'SET_ACTIVE_TABLE_LIST':
      return {
        ...state,
        activeTable: action.payload,
      };
    case 'INIT_TABLES':
      return {
        ...state,
        tables: action.payload,
      };
    case 'ADD_NEW_SECTION':
      return {
        ...state,
        sections: [...state.sections, action.payload],
      };
    case 'LOGOUT':
      return {
        sections: [],
        tables: [],
        activeTable: [],
      };
    default:
      return state;
  }
};
export default tablesReducer;
