import React from 'react';
import {useStyles} from '../styles';
import RefreshIcon from '@material-ui/icons/SyncRounded';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import {useDispatch, useSelector} from 'react-redux';
import {
  getTaxes,
  getTranslationData,
} from '../../../services/actions/preferencesActions';
import {getPreferences, getRestVouchers} from '../../../services/thunks';
import {
  getRestData,
  getUserLocations,
  logout,
  setReloadAPI,
} from '../../../services/actions';
import WhatsappIcon from '../svgs/WhatsappIcon';
import CustomWhatsapp from './CustomWhatsapp';

function HotMenu() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const route = useSelector((state) => state.router.location.pathname);
  const storeID = useSelector(
    (state) => state.auth.restaurant && state.auth.restaurant._id
  );

  const customWhatsappEnabled = useSelector(
    (state) => state.auth.restaurant && state.auth.restaurant.custom_whatsapp
  );
  // const qrVoucher = useSelector(
  //   (state) => state.auth.restaurant && state.auth.restaurant.qr_voucher
  // );

  const handleRefreshClicked = async (path) => {
    switch (path) {
      case '/active-tables':
        dispatch(setReloadAPI('active-tables'));
        break;
      case '/analytics':
        dispatch(setReloadAPI('analytics'));
        break;
      case '/menu':
        dispatch(setReloadAPI('manage-items'));
        break;
      case '/preferences':
        await dispatch(getRestData(storeID));
        await dispatch(getRestVouchers());
        await dispatch(getPreferences());
        await dispatch(getTaxes());
        await dispatch(getTranslationData());
        break;
      case '/tables':
        dispatch(setReloadAPI('manage-tables'));
        break;
      case '/employees':
        dispatch(setReloadAPI('manage-employees'));
        break;
      case '/retention':
        dispatch(setReloadAPI('retention'));
        break;
      case '/crm':
        dispatch(setReloadAPI('crm'));
        break;
      case '/payments':
        dispatch(setReloadAPI('payments'));
        break;
      case '/expenses':
        dispatch(setReloadAPI('expenses'));
        break;
      case '/recurring':
        dispatch(setReloadAPI('recurring'));
        break;
      case '/social-vouchers':
        dispatch(getRestVouchers());
        break;
      case '/map-my-customers':
        dispatch(getUserLocations());
        break;
      case '/loyalty':
        dispatch(setReloadAPI('loyalty'));
        break;
      case '/sms':
        dispatch(setReloadAPI('sms'));
        break;
      case '/whatsapp':
        dispatch(setReloadAPI('whatsapp'));
        break;
      case '/glide':
        dispatch(setReloadAPI('glide'));
        break;
      default:
        break;
    }
  };
  return (
    <div className={classes.iconFlex}>
      {customWhatsappEnabled && <CustomWhatsapp />}
      <Tooltip title="Support">
        <IconButton
          onClick={() => window.open('https://wa.me/919952705627', '_blank')}
        >
          <WhatsappIcon size="24" />
        </IconButton>
      </Tooltip>
      {route !== '/home' && route !== '/reports' && (
        <Tooltip title="Refresh">
          <IconButton onClick={() => handleRefreshClicked(route)}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Logout">
        <IconButton onClick={() => dispatch(logout())}>
          <PowerSettingsNewIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default HotMenu;
