// eslint-disable-next-line no-unused-vars
import * as actionTypes from '../../actionTypes';

const initState = {
  reloadAPI: '',
  isDataFetching: false,
  isLoading: false,
  language: localStorage.getItem('language')
    ? localStorage.getItem('language')
    : 'english',
  typeIndex: 0,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'RELOAD_API':
      return {
        ...state,
        reloadAPI: action.payload,
      };
    case 'DATA_FETCHING':
      return Object.assign({}, state, {isDataFetching: action.payload});
    case 'LOADING':
      return Object.assign({}, state, {isLoading: action.payload});
    case 'SET_LANGUAGE':
      localStorage.setItem('language', action.payload);
      return Object.assign({}, state, {
        language: action.payload,
      });
    case 'SET_NOTIFICATION_SOUND':
      return {
        ...state,
        notify_sound: action.payload,
      };
    case 'SELECTED_TYPE':
      return {
        ...state,
        type: action.payload,
      };
    case 'TYPE_INDEX':
      return {
        ...state,
        typeIndex: action.payload,
      };
    case 'LOGOUT':
      return {
        reloadAPI: '',
        isDataFetching: false,
        isLoading: false,
        typeIndex: 0,
      };
    default:
      return state;
  }
};

export default reducer;
