import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {setLoading} from '../miscActions';
import {http} from '../../http';
import {toast} from 'react-toastify';

export const getCustomerDetails = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restaurant._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/crm/cohorts/${storeID}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_CUSTOMER_DETAILS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get customer details');
      });
  };
};

export const getTemplates = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/templates`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_TEMPLATE_DETAILS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get templates');
      });
  };
};

export const addTemplates = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/templates`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.ADD_NEW_TEMPLATE, payload: res.data});
        await successCB();
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add templates');
      });
  };
};

export const updateTemplates = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/templates/${data._id}`, {
        name: data.name,
        message: data.message,
      })
      .then(async (res) => {
        await successCB();
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.UPDATE_TEMPLATE, payload: res.data});
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update template');
      });
  };
};

export const deleteTemplates = (ID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/templates/${ID}`)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.DELETE_TEMPLATE, payload: ID});
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete template');
      });
  };
};

export const purchaseMessage = (count, successCB, whatsapp) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    let url = `${API_URL}/stores/crm/msg/recharge`;
    const data = {};
    if (whatsapp) {
      url = `${API_URL}/stores/crm/wmsg/recharge`;
      data['whatsapp_count'] = count;
    } else {
      data['message_count'] = count;
    }
    http
      .post(url, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: 'INIT_PREFERENCES', payload: res.data});
        await successCB();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const sendWhatsappMessage = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/crm/campaign/whatsapp`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: 'INIT_PREFERENCES', payload: res.data});
        await successCB();
        await toast.success('Sending message to customers');
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const sendMessage = (data, successCB) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restaurant._id;
    http
      .post(`${API_URL}/crm/${storeID}/campaign`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: 'INIT_PREFERENCES', payload: res.data});
        await successCB();
        await toast.success('Sending message to customers');
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getCustomerDetailsQR = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/vouchers/users`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_CUSTOMER_QR_VOUCHER,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getLoyaltyDetails = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/loyalty/${storeID}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.INIT_LOYALTY, payload: res.data});
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addLoyaltyCredits = (data) => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/loyalty/${storeID}`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.ADD_LOYALTY, payload: res.data});
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const deleteLoyaltyCredits = (id, successCB) => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/loyalty/${storeID}/${id}`)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.DELETE_LOYALTY, payload: id});
        await successCB();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getQRVoucherBanners = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/banner/${storeID}?__type=VOUCHER`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_QR_VOUCHER_BANNER,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addNewQRVoucherBanner = (receivedFile) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restData._id;
    const data = new FormData();
    for (let i = 0; i < receivedFile.length; i++) {
      data.append('file', receivedFile[i]);
    }
    http
      .post(
        `${API_URL}/banner?__type=VOUCHER&platform=bolt&store_id=${storeID}`,
        data
      )
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getQRVoucherBanners());
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const deleteQRVoucherBanner = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/banner/${id}`)
      .then(() => {
        dispatch(getQRVoucherBanners());
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('unable to delete file');
        }
      });
  };
};

export const getQRVoucherApproval = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/cashback/${storeID}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_QR_VOUCHER_APPROVAL,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const approveVoucher = (cashbackID, data, cb) => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/vouchers/qr/${storeID}/${cashbackID}`, data)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getQRVoucherApproval());
        await cb();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getQRVoucherReport = (from, to) => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/cashback/${storeID}?active=false&from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_QR_VOUCHER_APPROVAL_REPORT,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getUserLocations = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/orders/users`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_USER_LOCATION, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getGlideList = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/scheduler`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_GLIDE, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createGlide = (data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/scheduler`, data)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getGlideList());
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteGlide = (id, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/scheduler/${id}`)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getGlideList());
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const uploadContactsFile = (receivedFile) => {
  return (dispatch) => {
    const data = new FormData();
    data.append('file', receivedFile);
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/stores/contacts`, data)
      .then(() => {
        dispatch(setLoading(false));
        toast.success('successfully uploaded');
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to upload file');
      });
  };
};
