import * as actionTypes from '../../actionTypes';

const initialState = {
  stories: [],
  feeds: {
    result: [],
    __metadata: {},
  },
};

export default function storyReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_STORY:
      return {
        ...state,
        stories: action.payload,
      };
    case actionTypes.INIT_FEED:
      return {
        ...state,
        feeds: action.payload,
      };
    case actionTypes.UPDATE_FEED_PAGINATION: {
      const tempStoreFeed = {...state.feeds};
      tempStoreFeed['__metadata'] = {...action.payload.__metadata};
      if (tempStoreFeed.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            tempStoreFeed.result.push(res);
          });
      }
      return {
        ...state,
        feeds: tempStoreFeed,
      };
    }
    case actionTypes.DELETE_STORY:
      const deleteStories = [...state.stories];
      const deleteIndex = deleteStories.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteIndex > -1) {
        deleteStories.splice(deleteIndex, 1);
      }
      return {
        ...state,
        stories: deleteStories,
      };
    case 'LOGOUT':
      return {
        stories: [],
        feeds: {
          result: [],
          __metadata: {},
        },
      };
    default:
      return state;
  }
}
