import * as actionTypes from '../../actionTypes';

const initialState = {
  business: {},
  businessCompare: {},
  menu: {},
  menuCompare: {},
  menuCombos: [],
  order: {},
  orderRevenueLeakage: {},
  orderCompare: {},
  customer: {},
  customerCompare: {},
  inventory: {},
};

export default function dashboardReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_DASHBOARD_BUSINESS:
      return {
        ...state,
        business: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_BUSINESS_COMPARE:
      return {
        ...state,
        businessCompare: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_MENU_COMBOS:
      return {
        ...state,
        menuCombos: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_ORDER_REVENUE_LEAKAGE:
      return {
        ...state,
        orderRevenueLeakage: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_INVENTORY:
      return {
        ...state,
        inventory: action.payload,
      };
    case actionTypes.CLEAR_DASHBOARD_COMPARE:
      return {
        ...state,
        businessCompare: {},
        menuCompare: {},
        orderCompare: {},
        customerCompare: {},
      };
    case 'LOGOUT':
      return {
        business: {},
        businessCompare: {},
        menu: {},
        menuCombos: [],
        menuCompare: {},
        order: {},
        orderRevenueLeakage: {},
        orderCompare: {},
        customer: {},
        customerCompare: {},
        inventory: {},
      };
    default:
      return state;
  }
}
