import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';

export const getAllEmployees = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/store_emp`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch({type: actionTypes.INIT_EMPLOYEES, payload: res.data});
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addNewStaff = (data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/store_emp`, data)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({type: actionTypes.ADD_NEW_EMPLOYEE, payload: res.data});
        if (data) {
          cb();
        }
        await dispatch(getAllEmployees());
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updateStaff = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    http
      .put(`${API_URL}/store_emp/${id}`, data)
      .then((res) => {
        dispatch({type: actionTypes.UPDATE_EMPLOYEE, payload: res.data});
        cb(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const deleteStaff = (id, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    http
      .delete(`${API_URL}/store_emp/${id}`)
      .then(() => {
        dispatch({type: actionTypes.DELETE_EMPLOYEE, payload: id});
        cb();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getStaffAttendance = (id, date) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/attendance/store_emp/monthly?date=${date}&emp_id=${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch({
          type: actionTypes.INIT_EMPLOYEE_ATTENDANCE,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updateStaffAttendance = (id, data, attendance) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/attendance/store_emp/${id}`, data)
      .then((res) => {
        dispatch(setLoading(false));
        if (attendance) {
          dispatch({
            type: actionTypes.UPDATE_STAFF_ATTENDANCE,
            payload: res.data,
          });
        } else {
          dispatch({
            type: actionTypes.UPDATE_EMPLOYEE_ATTENDANCE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getAllStaffAttendance = (date) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/attendance/store_emp/daily?date=${date}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch({
          type: actionTypes.INIT_ALL_EMPLOYEE_ATTENDANCE,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getAllStaffSalary = (date) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/salary/store_emp/monthly?date=${date}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch({
          type: actionTypes.INIT_EMPLOYEE_SALARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updateStaffSalary = (data, date, type) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const update = {
      amount: parseInt(data.pay_amount) ? parseInt(data.pay_amount) : 0,
      date: date,
      remarks: data.remarks,
    };
    http
      .post(`${API_URL}/salary/store_emp/${data.employee._id}/pay`, update)
      .then(async (res) => {
        if (type === 'monthly') {
          await dispatch(getAllStaffSalary(date));
        }
        if (type === 'daily') {
          await dispatch(getAllStaffDailySalary(date));
        }
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_SALARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getAllStaffDailySalary = (date) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/salary/store_emp/daily?date=${date}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch({
          type: actionTypes.INIT_EMPLOYEE_SALARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};
