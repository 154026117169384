import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import miscReducers from './miscReducers';
import analyticsReducers from './analyticsReducers';
import feedbackReducers from './feedbackReducers';
import itemReducers from './itemReducers';
import payoutReducers from './payoutReducers';
import preferencesReducers from './preferencesReduces';
import salesReducers from './salesReducers';
import tablesReducers from './tablesReducers';
import employeeReducers from './employeeReducers';
import crmReducers from './crmReducers';
import subscriberReducers from './subscriberReducers';
import dashboardReducers from './dashboardReducers';
import jukeboxReducers from './jukeboxReducers';
import expensesReducers from './expensesReducers';
import retentionReducers from './retentionReducers';
import beneficiaryReducers from './beneficiaryReducers';
import storyReducers from './storyReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    misc: miscReducers,
    item: itemReducers,
    feedback: feedbackReducers,
    salesReport: salesReducers,
    payout: payoutReducers,
    preferences: preferencesReducers,
    analytics: analyticsReducers,
    tables: tablesReducers,
    employees: employeeReducers,
    crm: crmReducers,
    subscriber: subscriberReducers,
    dashboard: dashboardReducers,
    jukebox: jukeboxReducers,
    expenses: expensesReducers,
    retention: retentionReducers,
    beneficiary: beneficiaryReducers,
    story: storyReducers,
  });

export default reducers;
