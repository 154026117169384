import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';

export const saveCategories = (data) => ({
  type: actionTypes.SAVE_CATEGORY_ANALYTICS,
  payload: data,
});

export const getCategoryAnalytics = () => {
  const la = window.localStorage.i18nextLng.split('-')[0]
    ? window.localStorage.i18nextLng.split('-')[0]
    : 'en';
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    // dispatch(getmostLikedItems());
    http
      .get(`${API_URL}/stores/${restaurantID}/analytics/category?lang=${la}`)
      .then((res) => {
        dispatch(saveCategories(res.data));
      })
      .catch(() => {});
  };
};

export const getItemsAnalytics = (query) => {
  const la = window.localStorage.i18nextLng.split('-')[0]
    ? window.localStorage.i18nextLng.split('-')[0]
    : 'en';
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    let url = `${API_URL}/stores/${restaurantID}/analytics/items/sold?lang=${la}`;
    if (query) {
      url = `${API_URL}/stores/${restaurantID}/analytics/items/sold?category=${query.id}&lang=${la}`;
    }
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: 'SAVE_ITEMS_ANALYTICS',
          payload: res.data.mostLiked,
        });
        if (query) {
          dispatch({type: 'ITEMS_ANALYTICS_FOR', payload: query});
        } else {
          dispatch({
            type: 'ITEMS_ANALYTICS_FOR',
            payload: {id: 'all', name: 'All'},
          });
        }
      })
      .catch(() => {});
  };
};

export const getmostLikedItems = () => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    http
      .get(`${API_URL}/stores/${restaurantID}/analytics/items/liked`)
      .then((res) => {
        if (res.data && res.data.mostLiked) {
          dispatch({type: 'SAVE_LIKED_ITEMS', payload: res.data.mostLiked});
        }
        if (res.data && res.data.mostDisliked) {
          dispatch({
            type: 'SAVE_DISLIKED_ITEMS',
            payload: res.data.mostDisliked,
          });
        }
      })
      .catch(() => {});
  };
};

export const getSalesAnalytics = (from, to) => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    const url = `${API_URL}/stores/${restaurantID}/analytics/dailyRevenue?from=${from}&to=${to}`;
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: 'SAVE_SALES_ANALYTICS',
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const getItemCountAnalytics = (from, to) => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    dispatch(setLoading(true));
    const url = `${API_URL}/stores/${restaurantID}/analytics/itemsCount?from=${from}&to=${to}`;
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: 'SAVE_ITEM_COUNT',
          payload: res.data,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadItemCount = (from, to) => {
  return (dispatch, getState) => {
    const la = window.localStorage.i18nextLng.split('-')[0]
      ? window.localStorage.i18nextLng.split('-')[0]
      : 'en';
    const restaurantID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${restaurantID}/excel/items/sold?lang=${la}&from=${from}&to=${to}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ItemCount.csv');
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getRushHoursData = (from, to) => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    const url = `${API_URL}/stores/${restaurantID}/orders/hours?from=${from}&to=${to}`;
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: 'SAVE_ORDER_ANALYTICS',
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const getMenuMargins = (from, to) => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData._id;
    const url = `${API_URL}/stores/${restaurantID}/item/margins?from=${from}&to=${to}`;
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: 'SAVE_MENU_MARGIN_ANALYTICS',
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};
