const initialState = {
  feedbackOpen: false,
  feedbackMessage: null,
};

export default function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case 'INVOKE_FEEDBACK':
      return Object.assign({}, state, {
        feedbackOpen: true,
        feedbackMessage: action.message,
      });
    case 'CLOSE_FEEDBACK':
      return Object.assign({}, state, {
        feedbackOpen: false,
        feedbackMessage: null,
      });
    case 'LOGOUT':
      return {
        feedbackOpen: false,
        feedbackMessage: null,
      };
    default:
      return state;
  }
}
