import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import {refreshData} from '../../thunks';
import {toast} from 'react-toastify';
import {http} from '../../http';
import {getRestData} from '../authActions';
import fileDownload from 'js-file-download';

export const initPreferences = (pref) => {
  return {
    type: 'INIT_PREFERENCES',
    payload: pref,
  };
};

export const updatePreferencesAction = (data, loadOnlypref, cb) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const state = getState();
    // const restaurantID = state.auth.restData._id;
    http
      .put(`${API_URL}/preferences`, {
        ...state.preferences.preferences,
        ...data,
      })
      .then((res) => {
        dispatch(initPreferences(res.data));
        !loadOnlypref && dispatch(refreshData());
        dispatch(setLoading(false));
        if (cb) {
          cb('false');
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update restaurant preferences');
        if (cb) {
          cb('true');
        }
      });
  };
};

export const getTaxes = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/tax`)
      .then((res) => {
        dispatch({type: 'INIT_TAX', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get taxes');
      });
  };
};

export const addTaxes = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/tax`, data)
      .then((res) => {
        dispatch({type: 'ADD_TAX', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add tax');
      });
  };
};

export const updateTaxes = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/tax/${data._id}`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_TAX', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update tax');
      });
  };
};

export const deleteTaxes = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/tax/${id}`)
      .then((res) => {
        dispatch({type: 'DELETE_TAX', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete tax');
      });
  };
};

export const addNewVoucher = (data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/vouchers`, data)
      .then((res) => {
        dispatch({type: 'ADD_VOUCHER', payload: res.data});
        dispatch(setLoading(false));
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add voucher');
      });
  };
};

export const addNewService = (dat) => {
  return (dispatch) => {
    const data = {
      services: dat,
    };
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/preferences`, data)
      .then((res) => {
        dispatch({type: 'INIT_PREFERENCES', payload: res.data.Restaurant});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add voucher');
      });
  };
};

export const updateVoucher = (id, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/vouchers/${id}`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_VOUCHER', payload: res.data.voucher});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toast.error('unable to update Voucher');
      });
  };
};

export const deleteVoucher = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/vouchers/${id}`)
      .then((res) => {
        dispatch({type: 'UPDATE_VOUCHER', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to deactivate voucher/rewards');
      });
  };
};

export const getFoodieCards = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/rest_gift_cards`)
      .then((res) => {
        dispatch({type: 'INIT_FOODIE_CARD', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add FoodieCards');
      });
  };
};

export const addFoodieCard = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/rest_gift_cards`, data)
      .then((res) => {
        dispatch({type: 'ADD_FOODIE_CARD', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add FoodieCards');
      });
  };
};

export const updateFoodieCard = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/rest_gift_cards/${data._id}`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_FOODIE_CARD', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update FoodieCards');
      });
  };
};

export const deleteFoodieCard = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/rest_gift_cards/${id}`)
      .then((res) => {
        dispatch({type: 'DELETE_FOODIE_CARD', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete FoodieCards');
      });
  };
};

export const searchFoodieCard = (code) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      // eslint-disable-next-line no-irregular-whitespace
      .get(`${API_URL}​/gift_cards/store/${code}`)
      .then((res) => {
        if (res.data.length === 0) {
          toast.warn('FoodieCard Not Found!');
        }
        dispatch({type: 'SEARCH_FOODIE_CARD', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('FoodieCard Not Found!');
      });
  };
};

export const UseFoodieCard = (code) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      // eslint-disable-next-line no-irregular-whitespace
      .post(`${API_URL}​/gift_cards/store/${code}`, {})
      .then((res) => {
        if (res.data.length === 0) {
          toast.warn('FoodieCard Not Found!');
        }
        dispatch({type: 'SEARCH_FOODIE_CARD', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toast.error('Invalid Verification Code');
      });
  };
};

export const uploadBannerImage = (receivedFile) => {
  return (dispatch, getState) => {
    const data = new FormData();
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    dispatch(setLoading(true));
    for (let i = 0; i < receivedFile.length; i++) {
      data.append('files', receivedFile[i]);
    }
    http
      .post(`${API_URL}/stores/${restaurantID}/promotion`, data)
      .then(() => {
        dispatch(getRestData(restaurantID));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to upload file');
      });
  };
};

export const deleteBannerImage = (url) => {
  return (dispatch, getState) => {
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/stores/${restaurantID}/promotion`, {
        data: {
          image_link: url,
        },
      })
      .then(() => {
        dispatch(getRestData(restaurantID));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete file');
      });
  };
};

export const getUrbanPiperStatus = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/up/status`)
      .then((res) => {
        dispatch({type: 'FETCH_URBAN_PIPER', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const initiateUPRestaurant = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/up/store`, {})
      .then((res) => {
        dispatch({type: 'FETCH_URBAN_PIPER', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updateUPStatus = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/up/status`, data)
      .then(() => {
        dispatch(initiateUPRestaurant());
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updateUPStore = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/up/store/update`)
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updateUPMenu = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/up/menu`, {})
      .then((res) => {
        dispatch({type: 'FETCH_URBAN_PIPER', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getTranslationData = () => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${restID}/translation`)
      .then((res) => {
        dispatch({type: 'SET_TRANSLATIONS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('Unable to get Translation info');
      });
  };
};

export const setTranslation = (data) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/stores/${restID}/translation`, {names: data})
      .then((res) => {
        // dispatch({type: 'SET_TRANSLATIONS', payload: res.data});
        dispatch(setLoading(false));
        toast.info(
          'Translation request received, please refresh after some time.'
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('Unable to get Translation info');
      });
  };
};

export const downloadDiscountQR = (id, name) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .post(
        `${API_URL}/vouchers/qr`,
        {
          store_id: restID,
          voucher_id: id,
        },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        dispatch(setLoading(false));
        fileDownload(response.data, `discount-qr-${name}.png`);
      })
      .catch(() => {
        toast.error('unable to genrate qr for this voucher');
        dispatch(setLoading(false));
      });
  };
};

export const downloadDiscountQRMultiple = (id, name) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .post(
        `${API_URL}/vouchers/qr/multiple`,
        {
          store_id: restID,
          voucher_id: id,
        },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        dispatch(setLoading(false));
        fileDownload(response.data, `discount-qr-${name}.png`);
      })
      .catch(() => {
        toast.error('unable to genrate qr for this voucher');
        dispatch(setLoading(false));
      });
  };
};

export const downloadTranslations = () => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${restID}/translation/download`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Translations.csv`);
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive translation for this store');
        dispatch(setLoading(false));
      });
  };
};

export const uploadTranslations = (receivedFile) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData._id;
    const data = new FormData();
    data.append('file', receivedFile);
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/stores/${restID}/translation/upload`, data)
      .then(() => {
        dispatch(setLoading(false));
        toast.success('successfully uploaded');
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to upload file');
      });
  };
};

export const updateGSTINCategory = (id, data) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData._id;
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/gst/${id}/category`, data)
      .then(() => {
        dispatch(setLoading(false));
        dispatch(getRestData(restID));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to update details');
      });
  };
};
