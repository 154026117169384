import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {setLoading} from '../miscActions';
import {http} from '../../http';
import {toast} from 'react-toastify';

export const getStories = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restaurant._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/story/${storeID}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_STORY,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get stories');
      });
  };
};

export const getFeedPost = (limit) => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restaurant._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/ita/posts/store/${storeID}?page=1&limit=${limit}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_FEED,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get feeds');
      });
  };
};

export const getFeedPostPagination = (url) => {
  return (dispatch) => {
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_FEED_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const deleteFeedPost = (id, limit) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/ita/posts/${id}`)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getFeedPost(limit));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete post');
      });
  };
};

export const uploadNewStory = (receivedFile) => {
  return (dispatch) => {
    const data = new FormData();
    dispatch(setLoading(true));
    for (let i = 0; i < receivedFile.length; i++) {
      data.append('files', receivedFile[i]);
    }
    http
      .post(`${API_URL}/story/image`, data)
      .then(() => {
        dispatch(getStories());
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to upload file');
      });
  };
};

export const uploadNewFeed = (data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/ita/posts`, data)
      .then(() => {
        dispatch(getFeedPost(10));
        cb();
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to create new feed');
      });
  };
};

export const deleteStory = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/story/${id}/remove`)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.DELETE_STORY,
          payload: id,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to delete story');
      });
  };
};
