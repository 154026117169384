import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import fileDownload from 'js-file-download';

export const getRestTables = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const restaurantID = getState().auth.restData._id;
  http
    .get(`${API_URL}/tables/${restaurantID}`)
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({type: 'INIT_TABLES', payload: res.data});
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const addTable = (data, successCB) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const storeID = getState().auth.restData._id;
  http
    .post(`${API_URL}/tables/${storeID}`, data)
    .then((res) => {
      dispatch(setLoading(false));
      successCB();
      dispatch(getRestTables());
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const getActiveTables = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const restaurantID = getState().auth.restData._id;
  http
    .get(`${API_URL}/orders/${restaurantID}/running_tables`)
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({type: 'SET_ACTIVE_TABLE_LIST', payload: res.data});
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const getStoreSections = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const storeID = getState().auth.restData._id;
  http
    .get(`${API_URL}/sections/${storeID}`)
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({type: 'INIT_SECTIONS', payload: res.data});
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const downloadTableQR = (tableID, tableName) => (dispatch, getState) => {
  const storeID = getState().auth.restData._id;
  dispatch(setLoading(true));
  http
    .get(`${API_URL}/qr/${storeID}/table/${tableID}`, {
      responseType: 'blob',
    })
    .then((response) => {
      fileDownload(response.data, `${tableName}_QR.png`);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const downloadTakeawayQR = () => (dispatch, getState) => {
  const storeID = getState().auth.restData._id;
  dispatch(setLoading(true));
  http
    .get(`${API_URL}/qr/${storeID}/takeaway`, {
      responseType: 'blob',
    })
    .then((response) => {
      fileDownload(response.data, `Takeaway_QR.png`);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const downloadMenuQR = () => (dispatch, getState) => {
  const storeID = getState().auth.restData._id;
  dispatch(setLoading(true));
  http
    .get(`${API_URL}/qr/${storeID}/menu`, {
      responseType: 'blob',
    })
    .then((response) => {
      fileDownload(response.data, `MENU_QR.png`);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const downloadQSRQR = () => (dispatch, getState) => {
  const storeID = getState().auth.restData._id;
  dispatch(setLoading(true));
  http
    .get(`${API_URL}/qr/${storeID}/qsr`, {
      responseType: 'blob',
    })
    .then((response) => {
      fileDownload(response.data, `QSR_QR.png`);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const downloadQMSQR = () => (dispatch) => {
  dispatch(setLoading(true));
  http
    .post(
      `${API_URL}/waiting_queue/qr`,
      {},
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      fileDownload(response.data, `QMS_QR.png`);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
};

export const createSection =
  (sectionName, successCB) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restData._id;
    http
      .post(`${API_URL}/sections/${storeID}`, {name: sectionName})
      .then((res) => {
        dispatch({type: 'ADD_NEW_SECTION', payload: res.data});
        successCB();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };

export const transferTableAPI =
  (sectionID, tableList, successCB) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restData._id;
    http
      .post(`${API_URL}/sections/${storeID}/${sectionID}/add/tables`, {
        tables: tableList,
      })
      .then(() => {
        dispatch(getRestTables());
        successCB();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
