const initialState = {
  taxes: [],
  foodieCards: [],
  voucher: {
    result: [],
    __metadata: {},
  },
  searchedFoodieCard: {},
  urbanPiper: {},
  translations: [],
};

export default function salesReportReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_PREFERENCES':
      return {
        ...state,
        preferences: {...action.payload},
      };
    case 'INIT_TAX':
      return {
        ...state,
        taxes: action.payload,
      };
    case 'ADD_TAX':
      const add_taxes = [...state.taxes];
      add_taxes.push(action.payload);
      return {
        ...state,
        taxes: add_taxes,
      };
    case 'UPDATE_TAX':
      const update_taxes = [...state.taxes];
      const update_taxes_index = update_taxes.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_taxes_index > -1) {
        update_taxes[update_taxes_index] = action.payload;
      }
      return {
        ...state,
        taxes: update_taxes,
      };
    case 'DELETE_TAX':
      const delete_taxes = [...state.taxes];
      const delete_taxes_index = delete_taxes.findIndex(
        (a) => a._id === action.payload._id
      );
      if (delete_taxes_index > -1) {
        delete_taxes.splice(delete_taxes_index, 1);
      }
      return {
        ...state,
        taxes: delete_taxes,
      };
    case 'INIT_FOODIE_CARD':
      return {
        ...state,
        foodieCards: action.payload,
      };
    case 'SEARCH_FOODIE_CARD':
      return {
        ...state,
        searchedFoodieCard: action.payload,
      };
    case 'ADD_FOODIE_CARD':
      const add_foodieCards = [...state.foodieCards];
      add_foodieCards.push(action.payload);
      return {
        ...state,
        foodieCards: add_foodieCards,
      };
    case 'UPDATE_FOODIE_CARD':
      const update_foodieCards = [...state.foodieCards];
      const update_foodieCards_index = update_foodieCards.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_foodieCards_index > -1) {
        update_foodieCards[update_foodieCards_index] = action.payload;
      }
      return {
        ...state,
        foodieCards: update_foodieCards,
      };
    case 'DELETE_FOODIE_CARD':
      const delete_foodieCards = [...state.foodieCards];
      const delete_foodieCards_index = delete_foodieCards.findIndex(
        (a) => a._id === action.payload._id
      );
      if (delete_foodieCards_index > -1) {
        delete_foodieCards.splice(delete_foodieCards_index, 1);
      }
      return {
        ...state,
        foodieCards: delete_foodieCards,
      };
    case 'INIT_VOUCHERS':
      return {
        ...state,
        voucher: action.payload,
      };
    case 'ADD_VOUCHER':
      const add_voucher = {...state.voucher};
      add_voucher.result.push(action.payload);
      return {
        ...state,
        voucher: add_voucher,
      };
    case 'UPDATE_VOUCHER':
      const update_voucher = {...state.voucher};
      const update_voucher_index = update_voucher.result.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_voucher_index > -1) {
        update_voucher.result[update_voucher_index] = action.payload;
      }
      return {
        ...state,
        voucher: update_voucher,
      };
    case 'DELETE_VOUCHER':
      const delete_voucher = {...state.voucher};
      const delete_voucher_index = delete_voucher.result.findIndex(
        (a) => a._id === action.payload._id
      );
      if (delete_voucher_index > -1) {
        delete_voucher.result.splice(delete_voucher_index, 1);
      }
      return {
        ...state,
        voucher: delete_voucher,
      };
    case 'FETCH_URBAN_PIPER':
      return {
        ...state,
        urbanPiper: action.payload,
      };
    case 'SET_TRANSLATIONS':
      return {
        ...state,
        translations: action.payload,
      };
    case 'LOGOUT':
      return {
        taxes: [],
        foodieCards: [],
        voucher: {
          result: [],
          __metadata: {},
        },
        searchedFoodieCard: {},
        urbanPiper: {},
        translations: [],
      };
    default:
      return state;
  }
}
