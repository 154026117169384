import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import queryString from 'query-string';

function PrivateAuthHoc({component: Component, ...rest}) {
  const restData = useSelector((state) => state.auth.restData);
  const token = restData.token ? restData.token : '';
  let url = '/';
  if (rest.path) {
    url += `?redirect=${rest.path}`;
    if (rest.location.search) {
      const data = queryString.parse(rest.location.search);
      url += `&redirect_search=${JSON.stringify(data)}`;
    }
  }
  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== '' ? <Component {...prop} /> : <Redirect to={url} />
      }
    />
  );
}

export default PrivateAuthHoc;
