import axios from 'axios';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {push, replace} from 'connected-react-router';
import {setLoading} from '../miscActions';
import {http} from '../../http';
import {
  getCategoryInfo,
  getGlobalAddonInfo,
  getIcons,
  getItemInfo,
  getPreferences,
  getRestVouchers,
} from '../../thunks';
import {getTaxes, getTranslationData} from '../preferencesActions';
import {toast} from 'react-toastify';
import {getStoreSections} from '../tablesActions';

export const login = (user, pwd, redirectURL) => {
  return (dispatch) => {
    dispatch({type: actionTypes.API_LOADING, payload: true});
    setTimeout(() => {
      dispatch({type: actionTypes.API_LOADING, payload: false});
    }, 5000);
    axios
      .post(`${API_URL}/stores/auth/login`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        await window.localStorage.setItem('auth_token', res.data.token);
        await window.localStorage.setItem(
          'refresh_token',
          res.data.refreshToken
        );
        await dispatch({type: actionTypes.REST_DATA, payload: res.data});
        await dispatch({type: actionTypes.API_LOADING, payload: false});
        await dispatch(push(redirectURL));
        await dispatch(getRestData(res.data._id));
        await dispatch(getItemInfo());
        await dispatch(getGlobalAddonInfo());
        await dispatch(getCategoryInfo());
        await dispatch(getPreferences());
        await dispatch(getCategoryInfo());
        await dispatch(getRestVouchers());
        await dispatch(getIcons());
        await dispatch(getTaxes());
        await dispatch(getTranslationData());
        await dispatch(getStoreSections());
      })
      .catch(() => {
        dispatch({type: actionTypes.API_LOADING, payload: false});
        dispatch(loginError(true));
      });
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return (dispatch, getState) => {
    dispatch({type: actionTypes.API_LOADING, payload: true});
    const storeID = getState().auth.restData._id;
    http
      .post(`${API_URL}/stores/${storeID}/reset_password`, {
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      .then(() => {
        toast.success('password change success, login with new password');
        setTimeout(() => {
          dispatch(logout());
        }, 2000);
        dispatch({type: actionTypes.API_LOADING, payload: false});
      })
      .catch(() => {
        toast.error('password change error, check again');
        dispatch({type: actionTypes.API_LOADING, payload: false});
      });
  };
};

export const getRestData = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${id}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_RESTAURANT_DETAILS,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getCustomWhatsappStatus = (storeID, softLoad) => {
  return (dispatch) => {
    if (!softLoad) {
      dispatch(setLoading(true));
    }
    http
      .get(`${API_URL}/whatsapp/venom/status?id=${storeID}`)
      .then(async (res) => {
        await dispatch({
          type: 'SET_CUSTOM_WHATSAPP_STATUS',
          payload: res.data,
        });
        if (!softLoad) {
          await dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch({
          type: 'SET_CUSTOM_WHATSAPP_STATUS',
          payload: {status: 'BACKEND_ERROR'},
        });
        if (!softLoad) {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          }
          dispatch(setLoading(false));
        }
      });
  };
};

export const initCustomWhatsapp = (storeID, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/whatsapp/venom/init?id=${storeID}`)
      .then(async (res) => {
        setTimeout(() => {
          dispatch(getCustomWhatsappStatus(storeID));
        }, 10000);
        cb();
        toast.success(res.data.status);
        await dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const getCustomWhatsappLogout = (storeID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/whatsapp/venom/${storeID}/logout`, {})
      .then(async (res) => {
        await dispatch({
          type: 'SET_CUSTOM_WHATSAPP_STATUS',
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getWalletDetails = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/wallet/details`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_WALLET_DETAILS,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const addMoneyToWallet = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/wallet/recharge`, data)
      .then(async (res) => {
        console.log('res.data', res.data);
        // if (res.data && res.data.payment && res.data.payment.payment_link) {
        //   const strWindowFeatures =
        //     'location=yes,height=900,width=600,scrollbars=yes,status=yes';
        //   window.open(
        //     res.data.payment.payment_link,
        //     '_blank',
        //     strWindowFeatures
        //   );
        // }
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateMarketingDetails = (data) => {
  return (dispatch, getState) => {
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/stores/${restaurantID}/marketing`, data)
      .then((res) => {
        if (res.data.data) {
          dispatch({
            type: actionTypes.SET_RESTAURANT_DETAILS,
            payload: res.data.data,
          });
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to upload file');
      });
  };
};

export const updateExternalCRMDetails = (data, cb) => {
  return (dispatch, getState) => {
    const authState = getState();
    const restaurantID = authState.auth.restData._id;
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/stores/${restaurantID}/external-crm-details`, data)
      .then((res) => {
        if (res.data.data) {
          dispatch({
            type: actionTypes.SET_RESTAURANT_DETAILS,
            payload: res.data.data,
          });
        }
        cb(false);
        dispatch(setLoading(false));
      })
      .catch(() => {
        cb(false);
        dispatch(setLoading(false));
        toast.error('unable to update details, contact sales team');
      });
  };
};

export const loginError = (value) => ({
  type: actionTypes.LOGIN_ERROR,
  payload: value,
});

export const logout = () => {
  return async (dispatch) => {
    // await window.localStorage.removeItem('auth_token');
    // await window.localStorage.removeItem('refresh_token');
    await dispatch({
      type: 'LOGOUT',
    });
    dispatch(replace('/'));
    // window.localStorage.clear();
    // window.location.reload();
  };
};
