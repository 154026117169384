import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import {useStyles} from '../styles';
import {useRouteMatch} from 'react-router';
import MenuSelection from '../../MenuSelection';
import DashboardIcon from '../svgs/DashboardIcon';
import ManageItemsIcon from '../svgs/ManageItemsIcon';
import LogoutIcon from '../svgs/LogoutIcon';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {logout} from '../../../services/actions/authActions';
import RestInfo from './RestInfo';
import PreferencesIcon from '../svgs/PreferencesIcon';
import SalesReportIcon from '../svgs/SalesReportIcon';
import AnalyticsIcon from '../svgs/AnalyticsIcon';
import {useTranslation} from 'react-i18next';
import ManageTableIcon from '../svgs/ManageTableIcon';
import LangSelector from './LangSelector';
import ProfileIcon from '../svgs/ProfileIcon';
import EmployeeIcon from '../svgs/EmployeeIcon';
import CrmIcon from '../svgs/CrmIcon';
import SubscriptionIcon from '../svgs/SubscriptionIcon';
import ActiveTableIcon from '../svgs/ActiveTableIcon';
import RetentionIcon from '../svgs/RetentionIcon';
import ExpensesIcon from '../svgs/ExpensesIcon';
import PaymentIcon from '../svgs/PaymentIcon';
import SocialVoucherIcon from '../svgs/SocialVoucherIcon';
import MapIcon from '../svgs/MapIcon';
import LoyaltyIcon from '../svgs/LoyaltyIcon';
import SMSCampaignIcon from '../svgs/SMSCampaignIcon';
import WhatsappIcon from '../svgs/WhatsappIcon';
import LeadGenIcon from '../svgs/LeadGenIcon';
import GlideIcon from '../svgs/GlideIcon';
// import DesignIcon from '../svgs/DesignIcon';

function MenuDrawer({open, handleClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  let menus = [
    {
      title: '',
      elements: [
        {
          label: t('Home'),
          active: useRouteMatch('/home'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/home'));
          },
          icon: <DashboardIcon />,
        },
      ],
    },
    {
      title: 'Data',
      elements: [
        {
          label: t('Analytics'),
          active: useRouteMatch('/analytics'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/analytics'));
          },
          icon: <AnalyticsIcon />,
        },
        {
          label: t('Reports'),
          active: useRouteMatch('/reports'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/reports'));
          },
          icon: <SalesReportIcon />,
        },
        {
          label: t('Active Tables'),
          active: useRouteMatch('/active-tables'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/active-tables'));
          },
          icon: <ActiveTableIcon />,
        },
      ],
    },
    {
      title: 'Settings',
      elements: [
        {
          label: t('Menu'),
          active: useRouteMatch('/menu'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/menu'));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: t('Preferences'),
          active: useRouteMatch('/preferences'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/preferences'));
          },
          icon: <PreferencesIcon />,
        },
        {
          label: t('Tables'),
          active: useRouteMatch('/tables'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/tables'));
          },
          icon: <ManageTableIcon />,
        },
        {
          label: t('Employees'),
          active: useRouteMatch('/employees'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/employees'));
          },
          icon: <EmployeeIcon />,
        },
        {
          label: t('Profile'),
          active: useRouteMatch('/profile'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/profile'));
          },
          icon: <ProfileIcon />,
        },
      ],
    },
    {
      title: 'Growth Hacking',
      elements: [
        {
          label: t('Customer segments'),
          active: useRouteMatch('/crm'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/crm'));
          },
          icon: <CrmIcon />,
        },
        {
          label: t('Lead gen'),
          active: useRouteMatch('/lead-gen'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/lead-gen'));
          },
          icon: <LeadGenIcon />,
        },
        {
          label: t('Social Vouchers'),
          active: useRouteMatch('/social-vouchers'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/social-vouchers'));
          },
          icon: <SocialVoucherIcon />,
        },
        {
          label: t('Map my customers'),
          active: useRouteMatch('/map-my-customers'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/map-my-customers'));
          },
          icon: <MapIcon />,
        },
        {
          label: t('Loyalty'),
          active: useRouteMatch('/loyalty'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/loyalty'));
          },
          icon: <LoyaltyIcon />,
        },
      ],
    },
    {
      title: 'Marketing automation',
      elements: [
        {
          label: t('FB/Insta'),
          active: useRouteMatch('/retention'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/retention'));
          },
          icon: <RetentionIcon />,
        },
        {
          label: t('SMS'),
          active: useRouteMatch('/sms'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/sms'));
          },
          icon: <SMSCampaignIcon />,
        },
        {
          label: t('Whatsapp'),
          active: useRouteMatch('/whatsapp'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/whatsapp'));
          },
          icon: <WhatsappIcon />,
        },
        {
          label: t('Glide'),
          active: useRouteMatch('/glide'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/glide'));
          },
          icon: <GlideIcon />,
        },
      ],
    },
    {
      title: 'Extra',
      elements: [
        {
          label: t('Payments'),
          active: useRouteMatch('/payments'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/payments'));
          },
          icon: <PaymentIcon />,
        },
        {
          label: t('Expenses'),
          active: useRouteMatch('/expenses'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/expenses'));
          },
          icon: <ExpensesIcon />,
        },
        {
          label: t('Recurring'),
          active: useRouteMatch('/recurring'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/recurring'));
          },
          icon: <SubscriptionIcon />,
        },
      ],
    },
    {
      title: '',
      elements: [
        {
          label: t('Logout'),
          active: false,
          handleMenu: () => {
            handleClose();
            dispatch(logout());
          },
          icon: <LogoutIcon />,
        },
      ],
    },
  ];

  const crmOnlyStore = useSelector(
    (state) => state.auth.restaurant?.crm_only_store
  );
  const tempData = [
    {
      title: '',
      elements: [
        {
          label: t('Home'),
          active: useRouteMatch('/home'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/home'));
          },
          icon: <DashboardIcon />,
        },
      ],
    },
    {
      title: 'Data',
      elements: [
        {
          label: t('Analytics'),
          handleMenu: () => dispatch(push('/analytics')),
          icon: <AnalyticsIcon />,
        },
      ],
    },
    {
      title: 'Growth Hacking',
      elements: [
        {
          label: t('Customer segments'),
          active: useRouteMatch('/crm'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/crm'));
          },
          icon: <CrmIcon />,
        },
        {
          label: t('Lead gen'),
          active: useRouteMatch('/lead-gen'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/lead-gen'));
          },
          icon: <LeadGenIcon />,
        },
        {
          label: t('Social Vouchers'),
          active: useRouteMatch('/social-vouchers'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/social-vouchers'));
          },
          icon: <SocialVoucherIcon />,
        },
        {
          label: t('Map my customers'),
          active: useRouteMatch('/map-my-customers'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/map-my-customers'));
          },
          icon: <MapIcon />,
        },
        {
          label: t('Loyalty'),
          active: useRouteMatch('/loyalty'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/loyalty'));
          },
          icon: <LoyaltyIcon />,
        },
      ],
    },
    {
      title: 'Marketing automation',
      elements: [
        {
          label: t('FB/Insta'),
          active: useRouteMatch('/retention'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/retention'));
          },
          icon: <RetentionIcon />,
        },
        {
          label: t('SMS'),
          active: useRouteMatch('/sms'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/sms'));
          },
          icon: <SMSCampaignIcon />,
        },
        {
          label: t('Whatsapp'),
          active: useRouteMatch('/whatsapp'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/whatsapp'));
          },
          icon: <WhatsappIcon />,
        },
        {
          label: t('Glide'),
          active: useRouteMatch('/glide'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/glide'));
          },
          icon: <GlideIcon />,
        },
      ],
    },
    {
      title: 'Settings',
      elements: [
        {
          label: t('Profile'),
          active: useRouteMatch('/profile'),
          handleMenu: () => {
            handleClose();
            dispatch(push('/profile'));
          },
          icon: <ProfileIcon />,
        },
      ],
    },
    {
      title: '',
      elements: [
        {
          label: t('Logout'),
          active: false,
          handleMenu: () => {
            handleClose();
            dispatch(logout());
          },
          icon: <LogoutIcon />,
        },
      ],
    },
  ];

  if (crmOnlyStore) {
    menus = [...tempData];
  }

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        <RestInfo />
        <div className={classes.menuList}>
          {menus.map((subMenu, idx) => (
            <div key={idx}>
              {subMenu.title && (
                <div
                  style={{margin: '16px 16px 8px', fontSize: 18, opacity: 0.6}}
                >
                  <fieldset
                    style={{
                      borderTop: '0.5px solid lightgray',
                      borderLeft: 'none',
                      borderBottom: 'none',
                      borderRight: 'none',
                      padding: 0,
                    }}
                  >
                    <legend>{subMenu.title}&nbsp;</legend>
                  </fieldset>
                </div>
              )}
              {subMenu.elements.map((menu, index) => (
                <MenuSelection
                  key={index}
                  title={subMenu.title}
                  active={menu.active}
                  label={menu.label}
                  handleClick={menu.handleMenu}
                >
                  {menu.icon}
                </MenuSelection>
              ))}
            </div>
          ))}
        </div>
        <div className={classes.bottomArea}>
          <LangSelector />
          <div className={classes.version}>
            V-{window.localStorage.getItem('appVersion')}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MenuDrawer;
